import React, { useState } from 'react';
import {
  CANADA_COUNTRY_CODE,
  createLocationsTree,
  getCanadaStatesNames,
  getCountryByCode,
  getUSAStatesNames,
  IPartnerLocationNode,
  LocationSubType,
  USA_COUNTRY_CODE
} from '../../utils/countryStatesUtils';
import { IPartnerDetails, IPartnerLocation } from '../../shared/models/partnerDetailsModel';
import { INavLink, INavLinkGroup, Link, Nav } from '@fluentui/react';
import { Trans } from 'react-i18next';
import { LOCATIONS, LINKS, LINKEDIN_PAGE } from '../../constants/localization';
import { ANALYTICS_USER_ACTIONS, trackUserAction } from '../../services/analyticsService';

export interface IPartnerDetailsAdditionalInfo {
  partner: IPartnerDetails;
  allLocations: IPartnerLocation[];
}

export default function PartnerDetailsAdditionalInfo(props: IPartnerDetailsAdditionalInfo) {
  const partner = props.partner;
  const [expandedKeys, setExpandedKeys] = useState([]);

  const getLocationNodeName = (locationNode: IPartnerLocationNode, parentNode?: IPartnerLocationNode): string => {
    if (!locationNode) {
      return null;
    }
    // City - show address
    if (locationNode.subType === LocationSubType.City) {
      let address = locationNode.location.address.addressLine1;
      if (locationNode.location.address.addressLine2 && locationNode.location.address.addressLine2 !== '') {
        address = address.endsWith(',') ? address + ' ' : address + ', ';
        address += locationNode.location.address.addressLine2;
      }
      if (locationNode.location.address.city && locationNode.location.address.city !== '') {
        address = address.endsWith(',') ? address + ' ' : address + ', ';
        address += locationNode.location.address.city;
      }
      return address;
      // Country - show name and children counter
    } else if (locationNode.subType == LocationSubType.Country) {
      const countryData = getCountryByCode(locationNode.name);
      if (countryData && countryData.name) return countryData.name + ' (' + locationNode.children.length + ')';
    }
    // State - show name and children counter
    else if (locationNode.subType == LocationSubType.State) {
      let stateData;
      if (parentNode.name === USA_COUNTRY_CODE) {
        stateData = getUSAStatesNames(locationNode.name);
      } else if (parentNode.name === CANADA_COUNTRY_CODE) {
        stateData = getCanadaStatesNames(locationNode.name);
      }
      if (stateData && stateData.name) return stateData.name + ' (' + locationNode.children.length + ')';
    } else return null;
  };

  const isExpanded = (key: string): boolean => {
    return expandedKeys.includes(key);
  };

  const getKey = (node: IPartnerLocationNode): string => {
    if (node.subType === LocationSubType.Country || node.subType === LocationSubType.State) {
      return node.name + '_' + node.subType;
    } else if (node.subType === LocationSubType.City) {
      return node.location.id;
    }
  };

  const handleExpandLinkChange = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
    trackUserAction({ name: ANALYTICS_USER_ACTIONS.DETAILS_LOCATION_TREE_CLICK, properties: { partnerId: partner?.universalAccountId } });
    if (item && item.key) {
      if (item.isExpanded) {
        const index = expandedKeys.indexOf(item.key);
        if (index > -1) {
          expandedKeys.splice(index, 1);
        }
      } else {
        const index = expandedKeys.indexOf(item.key);
        if (index === -1) {
          expandedKeys.push(item.key);
        }
      }
      setExpandedKeys(expandedKeys);
    }
  };

  const renderLocationGroups = () => {
    const locationsTree: IPartnerLocationNode[] = createLocationsTree(props.allLocations);
    const countryLinkGroups: INavLinkGroup[] = [{ links: [] }];
    for (let countryIndex = 0; countryIndex < locationsTree.length; countryIndex++) {
      const locationNode = locationsTree[countryIndex];
      const countryKey = locationNode.name + '_' + locationNode.subType;
      const countryNavItem = {
        name: getLocationNodeName(locationNode),
        links: [],
        url: undefined,
        key: countryKey,
        isExpanded: isExpanded(countryKey)
      };
      for (let stateOrCityIndex = 0; stateOrCityIndex < locationNode.children.length; stateOrCityIndex++) {
        const stateOrCityNode = locationNode.children[stateOrCityIndex];
        const stateOrCityKey = getKey(stateOrCityNode);
        // City nav key is address
        if (stateOrCityNode.subType == LocationSubType.City) {
          // Expend selected location
          if (stateOrCityNode.location.address.addressLine1 === partner.location.address.addressLine1) {
            countryNavItem.isExpanded = true;
          }
        }
        const stateOrCityNavItem = {
          name: getLocationNodeName(stateOrCityNode, locationNode),
          links: [],
          url: undefined,
          key: stateOrCityKey,
          isExpanded: isExpanded(stateOrCityKey)
        };
        countryNavItem.links.push(stateOrCityNavItem);
        for (let cityIndex = 0; cityIndex < stateOrCityNode.children.length; cityIndex++) {
          const cityAddressNode = stateOrCityNode.children[cityIndex];
          // Expend selected location
          if (cityAddressNode.location.address.addressLine1 === partner.location.address.addressLine1) {
            countryNavItem.isExpanded = stateOrCityNavItem.isExpanded = true;
          }
          const cityAdressKey = getKey(cityAddressNode);
          const cityNavItem = {
            name: getLocationNodeName(cityAddressNode, stateOrCityNode),
            links: [],
            url: undefined,
            key: cityAdressKey,
            isExpended: isExpanded(cityAdressKey)
          };
          stateOrCityNavItem.links.push(cityNavItem);
        }
      }
      countryLinkGroups[0].links.push(countryNavItem);
    }
    return countryLinkGroups;
  };

  const renderLocations = () => {
    return (
      <div aria-labelledby="partner-details-locations-header" role="region">
        <h5 className="title-section" id="partner-details-locations-header">
          <div className="title-text">
            <Trans i18nKey={LOCATIONS}>Locations</Trans>
          </div>
        </h5>
        <Nav onLinkExpandClick={handleExpandLinkChange} groups={renderLocationGroups()} selectedKey={partner.location.id} />
      </div>
    );
  };

  const renderLinkedinLink = () => {
    if (partner.linkedInOrganizationProfile)
      return (
        <Link
          className="c-hyperlink website-link"
          href={partner.linkedInOrganizationProfile}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            trackUserAction({
              name: ANALYTICS_USER_ACTIONS.DETAILS_PARTNER_LINKEDIN_LINK_CLICK,
              properties: { partnerId: partner?.universalAccountId }
            });
          }}
        >
          <Trans i18nKey={LINKEDIN_PAGE}>LinkedIn page</Trans>
        </Link>
      );
    else return null;
  };

  const renderWebsiteLink = () => {
    if (partner.url) {
      return (
        <Link
          className="c-hyperlink website-link"
          href={partner.url}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            trackUserAction({
              name: ANALYTICS_USER_ACTIONS.DETAILS_PARTNER_LINK_CLICK,
              properties: { partnerId: partner?.universalAccountId }
            });
          }}
        >
          {partner.name + ' website'}
        </Link>
      );
    } else return null;
  };

  const renderLinks = () => {
    if (!partner.url && !partner.linkedInOrganizationProfile) {
      return null;
    } else
      return (
        <div aria-labelledby="partner-details-links-header" role="region">
          <h5 className="title-section" id="partner-details-links-header">
            <div className="title-text">
              <Trans i18nKey={LINKS}>Links</Trans>
            </div>
          </h5>
          {renderWebsiteLink()}
          {renderLinkedinLink()}
        </div>
      );
  };

  if (partner) {
    return (
      <div className="additional-info-box">
        {renderLinks()}
        {renderLocations()}
      </div>
    );
  } else {
    return null;
  }
}
